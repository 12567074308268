<template>
  <div :style="{ height: contentStyleObj }">
    <qzf-tabs v-model:value="value" @tab-click="handleClick">
      <qzf-tabs-item label="税局检查" name="1" :cache="false">
        <taxCheckSj ref="batchSendSb" :activeName = "activeName"></taxCheckSj>
      </qzf-tabs-item>
      <!-- <qzf-tabs-item label="完税证明" name="2" :cache="false">
        <taxCheckProve ref="batchSendSb" :activeName = "activeName"></taxCheckProve>
      </qzf-tabs-item> -->
    </qzf-tabs>
  </div>
</template>

<script>
import taxCheckSj from "./components/taxCheckSj"
import taxCheckProve from "./components/taxCheckProve"
export default {
  name:'taxCheck',
  components:{
    taxCheckSj,
    taxCheckProve
  },
  data(){
    return {
      activeName:'first',
      value:'1',
      listQuery: {
        page: 1,
        limit: 20,
        setUpStatusCj:[1],
        from:'init'
      },
      contentStyleObj: {}, //高度变化
    }
  },
  created(){
    this.contentStyleObj = this.$getHeight(190);
  },
  methods:{
    
  }
}
</script>

<style lang="scss" scoped>

</style>